import React from 'react';
import './styles.scss';

const ScreenshotCaptureAPI = () => {
  return (
    <div className="tools-api-container">
      <h4 className="my-4 info-title" id="request-discover-tech-stack">
        To make a request for Screenshot capture result use this POST API.
      </h4>
      <h5>HTTP Request</h5>
      <pre className="pre">
        <p>
          <span className="btn-pre">POST</span>{' '}
          https://api.hexomatic.com/tool-api/screenshot-capture?key=&#123;YOUR_API_KEY&#125;
        </p>
      </pre>
      <h5>Parameters</h5>
      <table>
        <thead>
          <tr>
            <th>Fields</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="td-fill">YOUR_API_KEY</td>
            <td>
              <p className="bold-text">string</p>
              <p className="normal-text">
                {' '}
                to get YOUR_API_KEY visit hexomatic{' '}
                <a className="info-title" href="https://dash.hexomatic.com/settings" no-index>
                  settings{' '}
                </a>
                API/Webhook section and copy your key
              </p>
            </td>
          </tr>
        </tbody>
      </table>
      <h5>Request Body</h5>
      <table>
        <thead>
          <tr>
            <th>Fields</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="td-fill">source</td>
            <td>
              <p className="bold-text">string</p>
              <p className="normal-text">you should provide a source URL</p>
            </td>
          </tr>
          <tr>
            <td className="td-fill">devices</td>
            <td>
              <p className="bold-text">string[]</p>
              <p className="normal-text">you should provide array of devices</p>
            </td>
          </tr>
          <tr>
            <td className="td-fill">delay</td>
            <td>
              <p className="bold-text">number (optional)</p>
              <p className="normal-text">
                The amount of time the tool waits before the screenshot capture. The default value is 5s.
              </p>
              <p className="normal-text">Available values: 5, 10, 15, 30.</p>
            </td>
          </tr>
          <tr>
            <td className="td-fill">adBlock </td>
            <td>
              <p className="bold-text">boolean (optional)</p>
              <p>If provided true the tool will block ads before capturing. The default value is false.</p>
            </td>
          </tr>
          <tr>
            <td className="td-fill">height </td>
            <td>
              <p className="bold-text">number (optional)</p>
              <p className="normal-text">
                If not specified the tool will take a full screenshot. The height should be given in px.
              </p>
            </td>
          </tr>
        </tbody>
      </table>
      <h5>Example</h5>
      <pre className="pre">
        <p>{'{'}</p>
        <p className="mgl-25">"source": "https://example.com",</p>
        <p className="mgl-25">
          "devices": ["MOBILE_SMALL", "MOBILE_MEDIUM", "MOBILE_LARGE", "TABLET", "LAPTOP_SMALL","LAPTOP_MEDIUM",
          "LAPTOP_LARGE","DESKTOP_4K"],
        </p>
        <p className="mgl-25">"delay": 5,</p>
        <p className="mgl-25">"adBlock": true,</p>
        <p className="mgl-25">"height": 1000</p>
        <p>{'}'}</p>
      </pre>
      <h5>Response Body</h5>
      <p>
        if request is <strong className="info-title">successful</strong> you will get
      </p>
      <div className="json-title-block">
        <span>JSON representation</span>
      </div>
      <pre className="pre">
        <p>{'{'}</p>
        <p className="mgl-25">"taskId": "13f4b463-7e8a-429a-b62b-4afde618a154"</p>
        <p>{'}'}</p>
      </pre>

      <p>
        if request is <strong className="danger-color">not</strong> successful you will get
      </p>
      <div className="json-title-block">
        <span>JSON representation</span>
      </div>
      <pre className="pre">
        <p>{'{'}</p>
        <p className="mgl-25">"statusCode": 403,</p>
        <p className="mgl-25">"message": "Forbidden"</p>
        <p>{'}'}</p>
      </pre>

      <h4 className="my-4 info-title" id="get-discover-tech-stack">
        To get the result for Screenshot capture use this GET API.
      </h4>
      <h5>HTTP Request</h5>
      <pre className="pre">
        <p>
          <span className="btn-pre">GET</span>{' '}
          https://api.hexomatic.com/tool-api/result/&#123;TASK_ID&#125;?key=&#123;YOUR_API_KEY&#125;
        </p>
      </pre>
      <h5>Parameters</h5>
      <table>
        <thead>
          <tr>
            <th>Fields</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="td-fill">YOUR_API_KEY</td>
            <td>
              <p className="bold-text">string</p>
              <p className="normal-text">
                {' '}
                to get YOUR_API_KEY visit hexomatic{' '}
                <a href="https://dash.hexomatic.com/settings" no-index>
                  settings
                </a>{' '}
                and API/Webhook section and copy your key
              </p>
            </td>
          </tr>
          <tr>
            <td className="td-fill">TASK_ID</td>
            <td>
              <p className="bold-text">string</p>
              <p className="normal-text">
                {' '}
                if you did Screenshot capture POST request you must have received <strong>taskId</strong> that is valid
                one month after making the POST request. You can try to make this GET request with apx 5 minutes
                frequency after making the POST request for the tool.
              </p>
            </td>
          </tr>
        </tbody>
      </table>
      <h5>Response Body</h5>
      <p>if request is successful you will get</p>
      <div className="json-title-block">
        <span>JSON representation</span>
      </div>
      <pre className="pre">
        <p>{'{'}</p>
        <p className="mgl-25">"result": {'{'}</p>
        <p className="mgl-50"> "_device_screenshot_tablet": {'['}</p>
        <p className="mgl-75">
          "https://storage.googleapis.com/hexomatic-screenshot/screenshot-device-53b1e84f660ad853aef58ba761d97dfe-1644392413132.png"
        </p>
        <p className="mgl-50">{' ]'}</p>
        <p className="mgl-25">{'}'}</p>
        <p>{'}'}</p>
      </pre>
      <p>
        if request is <strong className="danger-color">not</strong> successful you will get
      </p>
      <div className="json-title-block">
        <span>JSON representation</span>
      </div>
      <pre className="pre">
        <p>{'{'}</p>
        <p className="mgl-25">"statusCode": 403,</p>
        <p className="mgl-25">"message": "Forbidden"</p>
        <p>{'}'}</p>
      </pre>
    </div>
  );
};

export default ScreenshotCaptureAPI;
