import React, {FC} from 'react';
import Section from '../../../components/section';
import {PageHero} from '../../../components/page-hero';
import Layout from '../../../components/layout/layout';
import Breadcrumb from '../../../components/breadcrumb';
import ScreenshotCaptureAPI from '../../../components/tools-api-requests/screenshot-capture';

const ApiDocumentationTechStack: FC = () => {
  return (
    <Layout>
      <PageHero title="" titleSmall minHeight id="seo_home_area_automations">
        <Breadcrumb
          items={[
            {
              to: `/automations`,
              name: 'Automations',
              active: false,
            },
            {
              to: `/automations/built-in-automations`,
              name: 'Built-in automations',
              active: false,
            },
            {
              to: `/automations/research`,
              name: 'Research',
              active: false,
            },
            {
              to: `/automation/create-screenshot/`,
              name: 'Screenshot capture',
              active: false,
            },
            {
              to: `/automation/create-screenshot/api-documentation`,
              name: 'API documentation',
              active: true,
            },
          ]}
        />
      </PageHero>
      <PageHero
        title="Screenshot capture API documentation"
        subtitle="This page provides API documentation for Screenshot capture"
        titleSmall
        minHeight
        id="seo_home_area_automations"
      ></PageHero>
      <Section>
        <ScreenshotCaptureAPI />
      </Section>
    </Layout>
  );
};

export default ApiDocumentationTechStack;
